import React from "react";
import "./card-service.scss";
import { Heading } from "../Heading/Heading";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const CardService = ({ title, description, image }) => {
  const parsedImage = getImage(image);
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div 
    className={`card-service ${isOpen ? "card-service--open" : ""}`}
    onClick={() => setOpen(!isOpen)}
    aria-hidden="true"
    >
      <div className="card-service__main">
        <GatsbyImage
          image={parsedImage}
          className="card-service__image"
          alt={title}
        />
        <Heading level="h3" white>
          {title}
        </Heading>
      </div>
      <div className="card-service__content">
        <Heading level="h4">{title}</Heading>
        <div className="card-service__details">{description}</div>
      </div>
    </div>
  );
};
