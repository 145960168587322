import React from 'react';
import './work-section.scss';

export const WorkSection = ({ children }) => {

  return (
    <section className="work-section">
      <div className="container">
        <div className="gr-12 no-gutter@md">
          <div className="work-section__holder">
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};