import React from "react"
import "./services.scss";
import { Heading } from '../../components/Heading/Heading';
import classNames from 'classnames';

export const Services = ({ children, title, description, twoCards }) => {
  return (
    <section className={classNames("services", {
      "services--two-cards": twoCards,
    })}>
      <div className="container">
        <div className="gr-12 no-gutter">
          <div className="services__text">
            <div className="wrap">
              <Heading level="h2" decorationDown>
                {title}
              </Heading>
              <p className="description">{description}</p>
            </div>
          </div>
        </div>
        <div className="gr-12 no-gutter">
          <div className="services__holder">
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Services;